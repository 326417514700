import './nav.scss';
import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ChangeLanguage from './ChangeLanguage';

const Nav = () => {
  const { t } = useTranslation();

  return (
  <nav id="nav">
    <ul id="main-nav">
      <li>
        <Link to="/sport/">{t('sport')}</Link>
      </li>
      <li>
        <Link to="/">{t('feed')}</Link>
      </li>
      <li>
        <Link to="/casino/">{t('casino')}</Link>
      </li>
    </ul>
    <ChangeLanguage/>
  </nav>
  );
};

export default Nav;