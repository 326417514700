import './styles/styles.scss';
import './i18n';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import Main from './main';

ReactDOM.render(
  <Main />, 
  document.getElementById('root')
);
