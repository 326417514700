import './shell.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ShellProps = {
  name: string
};

const Shell: React.FC<ShellProps> = (props: any) => {
  const { t } = useTranslation();

  return (
  <main className="shell">
    <header>
      <p>{t(props.name)}</p>
    </header>
  </main>
  );
};

export default Shell;