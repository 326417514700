import React from 'react';

const Loading: React.FC = () => {
  return (
  <div id="init-loading" style={{display: 'flex'}}>
    <span className="lds-dual-ring"></span>
  </div>
  );
};

export default Loading;