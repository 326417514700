import React,  { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Loading from './components/Loading';
import Nav from './components/Nav';
import Shell from './components/Shell';

const Main: React.FC = () => {
  return (
  <Suspense fallback={<Loading/>}>
    <Router>      
        <Nav/>
        <Switch>
          <Route path="/feed" exact render={(props) => <Shell {...props} name={"feed"} />}/>
          <Route path="/sport" exact render={(props) => <Shell {...props} name={"sport"} />}/>
          <Route path="/casino" exact render={(props) => <Shell {...props} name={"casino"} />}/>
          <Redirect from="/" to="/feed/" />
        </Switch>
    </Router>
  </Suspense>
  );
};

export default Main;
